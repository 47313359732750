import React, { useState } from "react"
import banner from '../images/contact-bg.jpg'
import contactImage from '../images/contact-image.png'
import Layout from "../components/layout"
import { Container, Row, Col, Form, Input, FormGroup, Button } from "reactstrap"
import { useFirebase } from 'gatsby-plugin-firebase'

const NotFoundPage = () => {


  const fb: any = null
  const [firebase, setFirebase] = useState(fb)
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [location, setLocation] = useState()
  const [message, setMessage] = useState()
  const [submited, setSubmited] = useState(false)
  useFirebase((fb: any) => {
    setFirebase(fb);
  }, [])

  const submitHendler = (event: any) => {
    event.preventDefault()

    const dataPost = {
      name, email, phone, location, message, page: 'contact-us'
    }
    firebase.database().ref("solutionsconsultants/websiteLeads").push(dataPost).then((d: any) => {
      console.log("Sudah Lewat Sini", d)
      setSubmited(true)
    }).catch(
      (err: any) => {
        console.log(err)
      }
    )
    console.log(dataPost)
  }
  console.log("submited", submited)
  let formDisplay = (
    <div style={{ padding: 30, background: '#ffffffe8', marginTop: 50 }} >
      <h5 style={{ textAlign: "center", fontSize: 22 }}  >
        Need help with setting and achieving your financial goals?  We’re here …. only a phone call or message away.  </h5>
      <h5 style={{ textAlign: "center", fontSize: 22 }}  >   Fill out the form below, and we’ll get in touch with you to discuss your needs and goals, and set up an appointment with our team. </h5>

      <Form>
        <div className="form-row" style={{ marginBottom: 15 }}>
          <div className="col-md-6" >
            <Input placeholder="Name" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
              //console.log(evt.target.value)
              setName(evt.target.value)
            }}></Input>
          </div>
          <div className="col-md-6">
            <Input placeholder="Email" type="email" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
              //console.log(evt.target.value)
              setEmail(evt.target.value)
            }}></Input>
          </div>
        </div>
        <div className="form-row" style={{ marginBottom: 15 }}>
          <div className="col-md-6">
            <Input placeholder="Phone" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
              //console.log(evt.target.value)
              setPhone(evt.target.value)
            }}></Input>
          </div>
          <div className="col-md-6" >
            <Input placeholder="Location" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
              //console.log(evt.target.value)
              setLocation(evt.target.value)
            }}></Input>
          </div>
        </div>

        <FormGroup>
          <label htmlFor="exampleFormControlTextarea1"> Message </label>
          <Input
            id="exampleFormControlTextarea1"
            rows="3"
            type="textarea"
            placeholder=" Is there anything specific you would like us to know? "
            className='form-control' onChange={(evt: any) => {
              //console.log(evt.target.value)
              setMessage(evt.target.value)
            }}
            style={{ border: "1px solid #e3e3e3", borderRadius: "10px", background: '#fff', fontSize: 16 }}
          ></Input>
        </FormGroup>
        <div style={{ textAlign: "center" }}>
          <Button className="btn-round" color="primary" type="button" onClick={(evt) => submitHendler(evt)}>
            <i className="now-ui-icons ui-1_send"></i> Submit </Button>
        </div>

      </Form>
    </div>
  )
  if (submited) {
    formDisplay = <div style={{ minHeight: 250, background: 'rgba(255, 255, 255, .9)', marginTop: 50, padding: 30, color: '#000', fontWeight: 500 }}>
      <p style={{ color: '#000', fontWeight: 500 }}>Hi {name}, </p>
      <p style={{ color: '#000', fontWeight: 500 }}>Thank you for submiting book an appointment Solutions Consultants, our technician will contact you  </p>
      <p style={{ color: '#000', fontWeight: 500 }}>For more information please contact us on</p>
      <p style={{ fontWeight: 500 }}>
        Phone <a style={{ fontWeight: 500 }} href="tel: +61 3 9826 6655">+61 3 9826 6655</a> <br />
        <a style={{ fontWeight: 500 }} href="mailto:info@solutionsconsultants.com.au" target="_blank">info@solutionsconsultants.com.au</a><br />
        <a style={{ fontWeight: 500 }} href="https://solutionsconsultants.com.au" target="_blank">https://solutionsconsultants.com.au</a><br />
      </p>
    </div>
  }
  return (
    <Layout setUid={() => {}} seo={{ title: "404: Not found", description: '', keyword: '' }} banner={<FreeConsulTationBanner />}  navStayle="SIMPLE" footerType="HOMEPAGE">
      <section>
        <Container>
          <Row className="justify-content-md-center" >
            <Col md={8}>
              {formDisplay}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )

}

const FreeConsulTationBanner = () => {
  return (
    <>
      <div style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'top center', paddingTop: 110 }}>
        <Container>
          <Row style={{ textAlign: "center" }}>
            <Col md={4} >
              <div style={{ marginTop: '50%', background: 'rgba(1, 107, 146, 0.75)', padding: '10px 0' }}>
                <h3 className="header-font" style={{ color: '#fff', fontSize: '3em', marginBottom: 0, width: 'auto' }}>Page Not Funnd </h3>
              </div>
            </Col>
            <Col md={8}>
              <img src={contactImage} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: 'rgb(1, 107, 146)', minHeight: 50 }}>

      </div>
    </>
  )
}


export default NotFoundPage
